import ScrollToTopButton from "components/button/ScrollToTop";
import TopNav from "components/nav/TopNav";
import { RAPID_TECH_INSIGHTS_DOMAIN } from "constants/global.constants";
import { Link, Outlet } from "react-router-dom";

function UserLayout() {
  return (
    <div className="bg-rt-light-violet min-h-screen flex flex-col">
      <TopNav />
      <main className="font-poppins flex-grow">
        <ScrollToTopButton />
        <Outlet />
      </main>

      <div className="flex flex-col items-center mt-[50px]">
        <div className="w-48 h-px bg-gray-300" />

        <div className="flex gap-4 my-4">
          <Link to="/faq" className="text-rt-blue hover:underline">
            Help
          </Link>
          <span className="text-gray-300">|</span>
          <Link to="/terms-of-service" className="text-rt-blue hover:underline">
            Terms of Service
          </Link>
          <span className="text-gray-300">|</span>
          <Link to="/privacy-policy" className="text-rt-blue hover:underline">
            Privacy
          </Link>
        </div>

        <div className="w-48 h-px bg-gray-300" />
      </div>

      <div className="flex justify-center items-center mt-4 mb-2">
        <p className="whitespace-nowrap">
          Made with <span className="text-rt-red">❤️</span> by
          <Link
            to={RAPID_TECH_INSIGHTS_DOMAIN}
            target="_blank"
            style={{
              color: "#0158b8",
              marginLeft: "4px",
            }}
          >
            Rapid Tech Insights
          </Link>
        </p>
      </div>
    </div>
  );
}

export default UserLayout;
